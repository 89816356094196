import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router';
import store from '@/store';
import { isPhone } from '@/utils/tools';

const routesMb = [{
        path: '',
        component: () =>
            import ('../views/mobile/index'),
        meta: {
            title: '图格兑卡网'
        }
    }, {
        path: "/index",
        name: "Index",
        component: () =>
            import ('../views/mobile/index'),
        meta: { requireAuth: false, title: '' }
    }, {
        path: "/sell",
        name: "Sell",
        component: () =>
            import ('../views/mobile/sell'),
        meta: { requireAuth: false, title: '我要卖卡' }
    }, {
        path: "/my",
        name: "My",
        component: () =>
            import ('../views/mobile/my'),
        meta: { requireAuth: true, title: '个人中心' }
    },
    {
        path: "/login",
        name: "Login",
        component: () =>
            import ('../views/mobile/login'),
        meta: { requireAuth: false, title: '登录' }
    },
    {
        path: "/help",
        name: "Help",
        component: () =>
            import ('../views/mobile/help'),
        meta: { requireAuth: false, title: '帮助' }
    },
    {
        path: "/moneylog",
        name: "MoneyLog",
        component: () =>
            import ('../views/mobile/money-log'),
        meta: { requireAuth: true, title: '回收记录' }
    },
    {
        path: "/freezelog",
        name: "FreezeLog",
        component: () =>
            import ('../views/mobile/freeze-log'),
        meta: { requireAuth: true, title: '冻结记录' }
    },
    {
        path: "/txlog",
        name: "TxLog",
        component: () =>
            import ('../views/mobile/tx-log'),
        meta: { requireAuth: true, title: '确认收款记录' }
    },
    {
        path: "/orderlog",
        name: "Orders",
        component: () =>
            import ('../views/mobile/orders'),
        meta: { requireAuth: true, title: '我的订单' }
    },
    {
        path: "/editinfo",
        name: "editInfo",
        component: () =>
            import ('../views/mobile/my-info'),
        meta: { requireAuth: true, title: '编辑信息' }
    },
    {
        path: "/tx",
        name: "tx",
        component: () =>
            import ('../views/mobile/tx'),
        meta: { requireAuth: true, title: '确认收款' }
    },
    {
        path: "/shiming",
        name: "shiming",
        component: () =>
            import ('../views/mobile/shiming'),
        meta: { requireAuth: true, title: '实名认证' }
    },
    {
        path: "/shimingv2",
        name: "shimingv2",
        component: () =>
            import ('../views/mobile/shimingv2'),
        meta: { requireAuth: false, title: '实名认证', keepAlive: false }
    },
    {
        path: "/article",
        name: "article",
        component: () =>
            import ('../views/mobile/article'),
        meta: { requireAuth: false, title: '' }
    },{
        path: "/articles",
        name: "Articles",
        component: () =>
            import ('../views/mobile/article-list'),
        meta: { requireAuth: false, title: '' }
    }, {
        path: "/check",
        name: "Loading",
        component: () =>
            import ('../views/loading'),
        meta: { requireAuth: true, title: '检测中' }
    }, {
        path: "/showpc",
        name: "showpc",
        component: () =>
            import ('../views/mobile/showpc'),
        meta: { requireAuth: true, title: '检测中' }
    }

]
const routesPc = [{
        path: '',
        component: () =>
            import ('../views/pc/index'),
        meta: { title: '图格兑卡网' }
    }, {
        path: "/index",
        name: "Index",
        component: () =>
            import ('../views/pc/index'),
        meta: { requireAuth: false, title: '' }
    }, {
        path: "/sell",
        name: "Sell",
        component: () =>
            import ('../views/pc/sell'),
        meta: { requireAuth: false, title: '我要卖卡' }
    },
    {
        path: "/business",
        name: "Business",
        component: () =>
            import ('../views/pc/business'),
        meta: { requireAuth: false, title: '商务合作' }
    },
    {
        path: "/my",
        name: "PcMy",
        component: () =>
            import ('../views/pc/my'),
        meta: { requireAuth: true, title: '个人中心' }
    }, {
        path: "/login",
        name: "Login",
        component: () =>
            import ('../views/pc/login'),
        meta: { requireAuth: false, title: '登录' }
    }, {
        path: "/shiming",
        name: "ShowQrcode",
        component: () =>
            import ('../views/pc/show-qrcode'),
        meta: { requireAuth: true, title: '实名认证' }
    }, {
        path: "/article",
        name: "Article",
        component: () =>
            import ('../views/pc/article'),
        meta: { requireAuth: false, title: '' }
    }, {
        path: "/check",
        name: "Loading",
        component: () =>
            import ('../views/loading'),
        meta: { requireAuth: true, title: '检测中' }
    }, {
        path: "/about",
        name: "About",
        component: () =>
            import ('../views/pc/about'),
        meta: { requireAuth: false, title: '关于我们' }
    },{
        path: "/articles",
        name: "Articles",
        component: () =>
            import ('../views/pc/articles'),
        meta: { requireAuth: false, title: '文章资讯' }
    }, {
        path: "/notice",
        name: "Notice",
        component: () =>
            import ('../views/pc/notice'),
        meta: { requireAuth: false, title: '公告' }
    }

]

var routes = [];
if (!isPhone()) {
    // console.log('is Mobile');
    routes = routesMb
} else {
    routes = routesPc
}

const router = createRouter({
    scrollBehavior() {
        return { left: 0, top: 0 }
    },
    routes,
    history: createWebHistory()
});

router.beforeEach((to, from, next) => {
    if (from.fullPath == '/shiming') {
        clearInterval(window.timeout)
    }
    if (to.meta.title) {
        document.title = to.meta.title
    } else {
        document.title = store.state.webConfig.web_name || '图格兑卡网 - 专业的礼品卡在线回收平台，话费卡回收/加油卡回收/游戏点卡回收/骏网智选卡回收，安全，专业，更靠谱！'
    }

    let head = document.getElementsByTagName('head');
    let meta_keyword = document.createElement('meta');
    let keywords = "充值卡转让,话费卡回收,点卡回收,礼品卡回收,游戏卡回收,京东E卡回收,加油卡回收,电商卡回收,天宏卡回收,骏网卡回收"
    let description = (to.meta.title || "") + "-专业礼品卡游戏卡骏网智充卡智选卡回收，京东e卡沃尔玛卡回收，可接api"
    if (document.querySelector('meta[name="keywords"]')) {
        document.querySelector('meta[name="keywords"]').setAttribute('content', keywords)
    } else {
        meta_keyword.setAttribute('name', 'keywords')
        meta_keyword.setAttribute('content', keywords)
        head[0].appendChild(meta_keyword)
    }
    let meta_description = document.createElement('meta');
    if (document.querySelector('meta[name="description"]')) {
        document.querySelector('meta[name="description"]').setAttribute('content', description)
    } else {
        meta_description.setAttribute('name', 'description')
        meta_description.setAttribute('content', description)
        head[0].appendChild(meta_description)
    }

    if (to.meta.requireAuth) { //判断该路由是否需要登录权限
        if (store.state.token) { //通过vuex的state获取当前的token是否存在
            next()
        } else {
            next({
                path: "/login",
                query: { redirect: to.fullPath } //将跳转的路由path作为参数，登陆成功后跳转到该路由
            })
        }
    } else {
        next();
    }


})

export default router